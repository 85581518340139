import { MetaSwiper, SafariSwiper } from "./meta-settings.js";
import { CountUp, hasSomeParentTheClass } from "./plugins/countUp.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";
$(document).ready(function () {
// plyr
  if (document.querySelector(".video-player")) {
    const videos = document.querySelectorAll(".video-player");

    videos.forEach((video) => {
      const player = new Plyr(video, {
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          "duration",
          "mute",
          "volume",
          "captions",
          "fullscreen",
        ],
      });

      player.volume = 0.2;
    });
  }

  window.snackSimplebar = function snackSimplebar() {
    let containers = document.querySelectorAll(".snackbar-container");
    containers.forEach((container) => {
      let div = document.createElement("div");
      let content = container.querySelector("p").innerHTML;
      container.querySelector("p").innerHTML = "";
      container.querySelector("p").appendChild(div);
      div.innerHTML = content;
      div.setAttribute("data-simplebar", "");
    });
  };

  var myHash = location.hash;
  if (myHash[1] != undefined) {
    location.hash = "";
    $("html, body").animate({ scrollTop: $(myHash).offset().top - 120 }, 500);
  }

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  AOS.init({
    offset: 250,
    delay: 0,
    duration: 400,
    easing: "ease",
    once: true,
  });
  // nice-select

  const registrationSelect = document.querySelector(".registration__select");

  if (document.querySelector("#share-code")) {
    const shareCode = document.querySelector("#share-code");

    registrationSelect.addEventListener("addItem",  () => {
      const nationality = registrationSelect.value;
      if (nationality.length && (nationality === "British") || (nationality === "Irish")) {
        shareCode.classList.add("hidden");
      } else {
        shareCode.classList.remove("hidden");
      }
    });
  }

  // end nice-select

  // burger-menu
  const burgerMenu = (menuSelector, burgerSelector) => {
    const menuElem = document.querySelector(menuSelector),
      layoutElem = document.querySelector(".header__layout"),
      burgerElem = document.querySelector(burgerSelector);

    const removeClass = () => {
      menuElem.classList.remove("open");
    };
    removeClass();

    menuElem.querySelectorAll("a").forEach((i) => {
      i.addEventListener("click", (e) => {
        if (menuElem.classList.contains("open")) {
          removeClass();
        }
      });
    });

    burgerElem.addEventListener("click", () => {
      if (menuElem.classList.contains("open")) {
        removeClass();
      } else {
        menuElem.classList.add("open");
      }
    });

    layoutElem.addEventListener("click", () => {
      removeClass();
    });
  };

  burgerMenu(".header", ".header__burger");
  // end burger-menu

  // double-range-slider
  const doubleRangeSliderTooltips = () => {
    const doubleRange = document.getElementsByClassName("filter__range-box")[0];
    if (doubleRange) {
      const slider = doubleRange.querySelector("#double-range-tooltips");
      const max = +slider.dataset.max;
      const min = +slider.dataset.min;
      const unit = slider.dataset?.unit || "£";
      const step = +slider.dataset.step;
      const inputsHidden = doubleRange.querySelectorAll(".filter__range-input");
      const startValueMin = +inputsHidden[0].value;
      const startValueMax = +inputsHidden[1].value;

      // how many percentages
      const borderLimit = 8;
      const borderDiff = 40 / borderLimit;

      noUiSlider.create(slider, {
        start: [startValueMin, startValueMax],
        connect: true,
        tooltips: true,
        margin: 10,
        step: step,
        range: {
          min: min,
          max: max,
        },
      });

      const tooltipsArr = slider.querySelectorAll(".noUi-tooltip");
      const circlesArr = slider.querySelectorAll(".noUi-origin");

      function returnTransform(element) {
        return element
          .replace(/[^0-9][^\d.]/g, "")
          .replace(")", "")
          .split(" ")
          .map((str) => {
            return Number(str);
          });
      }

      function needToMerge() {
        let tooltipOnePosition = tooltipsArr[0].getBoundingClientRect();
        let tooltipTwoPosition = tooltipsArr[1].getBoundingClientRect();

        if (
          tooltipsArr[0].classList.contains("hidden") ||
          tooltipsArr[1].classList.contains("hidden")
        ) {
          return true;
        }

        return (
          tooltipOnePosition.left +
            tooltipOnePosition.width -
            tooltipTwoPosition.left >
          0
        );
      }

      function resetTooltips(values) {
        mergeDiff = null;
        tooltipsArr.forEach((elem, index) => {
          elem.textContent =
            unit + Math.round(values[index]).toLocaleString("en-GB");
          elem.classList.remove("hidden");
        });
      }

      let trueTooltip = false;
      let mergeDiff = null;

      slider.noUiSlider.on("update", function (values, handle) {
        let translate = returnTransform(circlesArr[handle].style.transform)[0];
        let valueMin = returnTransform(circlesArr[0].style.transform)[0];
        let valueMax = returnTransform(circlesArr[1].style.transform)[0];

        let difference = valueMax - valueMin;

        inputsHidden[handle].value = Math.round(values[handle]);

        if (translate <= -100 + borderLimit) {
          tooltipsArr[0].style.transform = `translate(${
            -60 + Math.abs(translate + 100 - borderLimit) * borderDiff
          }%,100%)`;
        } else if (translate >= -borderLimit) {
          tooltipsArr[1].style.transform = `translate(${
            -50 - Math.abs(translate + borderLimit) * borderDiff
          }%,100%)`;
        } else {
          tooltipsArr[handle].style.transform = "translate(-50%,100%)";
        }

        if (needToMerge()) {
          if (
            !tooltipsArr[+!handle].classList.contains("hidden") &&
            !tooltipsArr[handle].classList.contains("hidden")
          ) {
            trueTooltip = handle;
            mergeDiff = difference;
            tooltipsArr[+!handle].classList.add("hidden");
          }

          if (trueTooltip) {
            tooltipsArr[trueTooltip].style.transform = `translate(${
              -10 - difference
            }%,100%)`;
          } else {
            // if grabbed right dot
            tooltipsArr[trueTooltip].style.transform = `translate(${
              -95 + difference
            }%,100%)`;
          }

          tooltipsArr[trueTooltip].textContent = `${
            unit + Math.round(values[0]).toLocaleString("en-GB")
          } - ${unit + Math.round(values[1]).toLocaleString("en-GB")}`;

          if (mergeDiff && difference > mergeDiff) {
            mergeDiff = null;
            resetTooltips(values);

            if (trueTooltip) {
              tooltipsArr[0].style.transform = `translate(-20%, 100%)`;
            } else {
              tooltipsArr[1].style.transform = `translate(-90%, 100%)`;
            }
          }
        } else {
          resetTooltips(values);
        }
      });
    }
  };
  doubleRangeSliderTooltips();

  // end double-range-slider

  // accardion
  $(".benefits__head.open").toggleClass("active").prev().slideToggle();

  $(".benefits__head").on("click", function (e) {
    e.preventDefault();
    var $this = $(this);

    if (!$this.hasClass("active")) {
      $(".benefits__content").slideUp(400);
      $(".benefits__head").removeClass("active");
    }

    $this.toggleClass("active");
    $this.prev().slideToggle();
  });

  $(".registration__head.active").next().slideDown();

  $(".registration__head").on("click", function (e) {
    $(this).toggleClass("active");
    $(this).next().slideToggle();
  });

  // end accardion

  // sticky
  function stickyMenu() {
    const headerElem = document.querySelector(".header");
    if (window.pageYOffset >= 10) {
      headerElem.classList.add("sticky");
    } else {
      headerElem.classList.remove("sticky");
    }
  }

  window.addEventListener("scroll", stickyMenu);
  stickyMenu();
  // end sticky

  // countUp
  let countUpArr = new Array();

  function CountUpFunction(wrapper, id, finalNum, option = {}) {
    let defaultOptions = {
      separator: "",
      enableScrollSpy: true,
      scrollSpyRunOnce: true,
    };

    function getNumber(str){
      let suff = str.replace(/[0-9]/g, '')
      let nums = +str.replace(/[\D]+/g, '')
      if (suff.length > 0) {
        const span = document.createElement('span')
        span.innerHTML = suff
        wrapper.appendChild(span)
      }
      return nums
    }
    finalNum = getNumber(finalNum)

    let mergeOptions = { ...defaultOptions, ...option };
    countUpArr.push(new CountUp(id, finalNum, mergeOptions));
  }

  const counters = document.querySelectorAll(".counter__box");
  counters.forEach((wrapper, i) => {
    const counter = wrapper.querySelector('.counter__val');
    const value = counter.getAttribute("data-count");
    CountUpFunction(wrapper, counter, value, { suffix: "" });
  });
  // end countUp

  // Swiper
  window.registerSlider = function registerSlider() {
    const registerSlider = document.querySelector(".register-form__slider");
    if (registerSlider) {
      MetaSwiper(".register-form__slider", {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 20,
        allowTouchMove: false,
        pagination: {
          el: ".register-form__pagination",
          type: "progressbar",
        },
        navigation: {
          nextEl: ".register-form__next",
          prevEl: ".register-form__prev",
        },
      });
    }
  };

  if (document.querySelector(".counter__slider")) {
    MetaSwiper(".counter__slider", {
      loop: false,
      grabCursor: true,
      slidesPerView: "auto",
      spaceBetween: 0,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
    });
  }

  if (document.querySelector(".partners__slider")) {
    if ($(window).width() > 768) {
      const partnersSwiperContainer =
        document.querySelector(".partners__slider");
      const partnersSwiper = MetaSwiper(".partners__slider", {
        loop: false,
        slidesPerView: "auto",
        spaceBetween: 10,
        speed: 500,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        breakpoints: {
          768: {
            spaceBetween: 35,
            slidesPerView: "auto",
          },
          1920: {
            spaceBetween: 45,
            slidesPerView: "auto",
          },
        },
      });
    }
  }

  if (document.querySelector(".jobs__slider")) {
    MetaSwiper(`.jobs__slider`, {
      loop: false,
      grabCursor: true,
      slidesPerView: "auto",
      spaceBetween: 36,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".jobs__btn-prev",
        nextEl: ".jobs__btn-next",
      },
      breakpoints: {
        1024: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        1920: {
          slidesPerView: "auto",
          spaceBetween: 35,
        },
      },
    });
  }

  if (document.querySelector(".news__slider")) {
    MetaSwiper(`.news__slider`, {
      loop: false,
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 36,
      grid: {
        rows: 3,
      },
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".news__btn-prev",
        nextEl: ".news__btn-next",
      },
      breakpoints: {
        569: {
          spaceBetween: 25,
          slidesPerView: "auto",
          grid: {
            rows: 1,
          },
        },
        769: {
          slidesPerView: "auto",
          spaceBetween: 35,
          grid: {
            rows: 1,
          },
        },
        1024: {
          slidesPerView: "auto",
          spaceBetween: 60,
          grid: {
            rows: 1,
          },
        },
      },
    });
  }

  if (document.querySelector(".values__slider--our")) {
    MetaSwiper(`.values__slider--our`, {
      loop: false,
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 0,
      grid: {
        rows: 2,
      },
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        569: {
          slidesPerView: 2,
          grid: {
            rows: 2,
          },
        },
        1024: {
          slidesPerView: 3,
          grid: {
            rows: 2,
          },
        },
      },
    });
  }
  if (document.querySelector(".values__slider--join")) {
    MetaSwiper(`.values__slider--join`, {
      loop: false,
      grabCursor: true,
      slidesPerView: 2,
      spaceBetween: 0,
      grid: {
        rows: 3,
      },
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        568: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 5,
        },
      },
    });
  }

  if (document.querySelector(".timeline__slider")) {
    const timelineSlider = document.querySelector(".timeline__slider");
    const timelineSlides = timelineSlider.querySelectorAll(".timeline__slide");

    MetaSwiper(`.timeline__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 20,
      thumbs: {
        swiper: MetaSwiper(".timeline__pagination", {
          loop: false,
          spaceBetween: 0,
          slidesPerView: "auto",
          freeMode: true,
          watchSlidesProgress: true,
        }),
      },
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".timeline__btn-prev",
        nextEl: ".timeline__btn-next",
      },
    });
  }

  if (document.querySelector(".cases__slider")) {
    MetaSwiper(`.cases__slider`, {
      loop: false,
      grabCursor: true,
      slidesPerView: "auto",
      spaceBetween: 37,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".cases__btn-prev",
        nextEl: ".cases__btn-next",
      },
      breakpoints: {
        1024: {
          slidesPerView: "auto",
          spaceBetween: 60,
        },
      },
    });
  }
  // end Swiper

  // custom input
  $(function () {
    $.each($(".js-input-group"), function () {
      var input = new inputHandler($(this));
    });
  });

  function inputHandler($container) {
    this._$inputContainer = $container;
    this._$input = this._$inputContainer.find("input");

    // add event watchers
    this._handleFocus();
    this._handleBlur();
    this._valueHandler();
  }

  inputHandler.prototype._handleFocus = function () {
    var that = this;
    this._$input.on("focus", function () {
      that._$inputContainer.addClass("focus");
    });
  };

  inputHandler.prototype._handleBlur = function () {
    var that = this;
    this._$input.on("blur", function () {
      if (!that._inputHasValue) {
        that._$inputContainer.removeClass("focus");
      }
    });
  };

  inputHandler.prototype._valueHandler = function () {
    var that = this;
    this._$input.on("keyup", function () {
      if (that._$input.val()) {
        that._inputHasValue = true;
        that._$inputContainer.addClass("has-content");
      } else {
        that._inputHasValue = false;
        that._$inputContainer.removeClass("has-content");
      }
    });
  };

  // end custom input

  function iOSversion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
      var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    }
  }

  // Titles animation
  const allTitles = [...document.getElementsByClassName(`title`)];
  if (allTitles.length > 0) {
    const iOSVersion = iOSversion();
    const safariVersion = String(navigator.sayswho).split(" ");
    let flag = false;

    // return all under Safari 12 Desktop
    if (safariVersion[0] === "Safari" && Number(safariVersion[1]) < 12) {
      flag = true;
    }

    // return all under Safari 12 iOS
    if (
      iOSVersion &&
      (iOSVersion[0] < 12 || (iOSVersion[0] === 12 && iOSVersion[1] < 2))
    ) {
      flag = true;
    }

    if (!flag) {
      const observerTitles = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("show");
            }
          });
        },
        { threshold: 0.3 }
      );

      for (let i = 0; i < allTitles.length; i++) {
        // Show titles
        observerTitles.observe(allTitles[i]);

        const text = allTitles[i].innerText;

        const wordArr = allTitles[i].textContent.trim().split(" ");
        allTitles[i].innerHTML = "";

        const spanArr = [];

        wordArr.forEach((elem) => {
          const span = document.createElement("span");
          span.innerHTML = `${elem}`;
          spanArr.push(span);
        });

        let accum = 0;

        for (let j = 0; j < spanArr.length; j++) {
          const spanFather = document.createElement("span");
          const letterArr = spanArr[j].innerText.trim().split("");

          if (j > 0) accum += spanArr[j - 1].innerText.length;
          if (j === 4 || j === spanArr.length - 1)
            spanFather.classList.add("orange");

          for (let k = 0; k < letterArr.length; k++) {
            const span = document.createElement("span");

            span.innerHTML = letterArr[k];

            span.style.cssText = `transition-delay: ${(accum + k) * 100}ms`;

            spanFather.append(span);
          }

          const span = document.createElement("span");
          span.innerHTML = "&nbsp;";
          spanFather.append(span);
          allTitles[i].append(spanFather);
        }
      }
    }
  }

  function nameCut(wrapper, input, label) {
    const wrappers = document.querySelectorAll(wrapper);

    wrappers.forEach((wrapper) => {
      const upload = wrapper.querySelector(input);
      const uploadLabel = wrapper.querySelector(label);

      if (upload && uploadLabel) {
        upload.addEventListener("change", function () {
          let dots;
          const arr = this.files[0].name.split(".");
          arr[0].length > 6 ? (dots = "...") : (dots = ".");
          uploadLabel.innerHTML = arr[0].substring(0, 10) + dots + arr[1];
        });
      }
    });
  }
  if (document.querySelector(".registration__file")) {
    nameCut(".registration__file", ".file-upload", ".registration__title");
  }

  function getNormalDate() {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${
      day < 10 ? "0" + day : day
    }/${month < 10 ? "0" + month : month}/${year}`;
  }

  document.querySelectorAll(".today-js").forEach((input) => {
    input.value = getNormalDate();
  });

  $(".date-expiry-js").each(function () {
    $(this).daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      parentEl: $(this).parent(),
      drops: "up",
      minYear: parseInt(moment().format('YYYY'),10),
      maxYear: parseInt(moment().format('YYYY'),10) + 10,
      autoUpdateInput: false,
      opens: "left",
    });

    $(this).on("apply.daterangepicker", function (ev, picker) {
      $(this).val(picker.startDate.format("DD/MM/YYYY"));
    });

    $(this).on("cancel.daterangepicker", function (ev, picker) {
      $(this).val("");
    });
  });

  $(".date-js").each(function () {
    $(this).daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      parentEl: $(this).parent(),
      drops: "up",
      minYear: 1901,
      maxYear: parseInt(moment().format("YYYY"), 10),
      autoUpdateInput: false,
      opens: "left",
    });

    $(this).on("apply.daterangepicker", function (ev, picker) {
      $(this).val(picker.startDate.format("DD/MM/YYYY"));
    });

    $(this).on("cancel.daterangepicker", function (ev, picker) {
      $(this).val("");
    });
  });
});

tippy("#registration__tippy", {
  content:
    "Please upload a clear photograph or scan of your proof of national insurance number. Documentation accepted: NINO Card, Payslip, P45, P60, or a Letter from HMRC or DWP",
});
tippy("#registration__right-work", {
  content:
    "Please upload documentation to evidence your Right to Work as stated in the lists above",
});

window.validateForm = function validateForm(responce) {
  const topElement = {
    element: null,
    top: null,
  };

  const errorFieldsNames = responce.error_key_fields;
  errorFieldsNames.forEach((str, index) => {
    const nameFormatted = str.split("|");

    nameFormatted.forEach((name) => {
      const input = document.querySelector(`[name="${name}"]`);

      // search Parent Block
      const parentBlock =
        input?.closest(".registration__input") ||
        input?.closest(".registration__checkbox");

      if (index === 0) {
        topElement.element = parentBlock;
        topElement.top = parentBlock?.getBoundingClientRect().top;
      }

      // .has-error to whow it on front
      if (input) {
        parentBlock?.classList.add("has-error");
        const parentBlockPosition = parentBlock?.getBoundingClientRect();

        if (parentBlockPosition?.top < topElement?.top) {
          topElement.element = parentBlock;
          topElement.top = parentBlock?.getBoundingClientRect().top;
        }
      }

      // remove .has-error after inputting
      function clearError() {
        parentBlock?.classList.remove("has-error");
        input.removeEventListener("input", clearError);
        input.removeEventListener("addItem", clearError);
      }

      // different removing .has-error
      if (input.type === "radio") {
        const twoRadioInputs = document.querySelectorAll(`[name="${name}"]`);
        twoRadioInputs.forEach((radio) => {
          radio.addEventListener("input", clearError);
        });
      } else if (input.classList.contains("date-js")) {
        $(input).on("apply.daterangepicker", clearError);
      } else if (input.tagName === "SELECT") {
        input.addEventListener("addItem", clearError);
      } else {
        input.addEventListener("input", clearError);
      }
    });
  });
  // scroll to element that are not validated on top
  topElement.element.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
};
if (document.querySelector('input[name=\'endorsements\']')) {
  const endorsementsRadios = document.querySelectorAll("input[name='endorsements']")
  const transportRadios = document.querySelectorAll("input[name='have_transport']")
  const noInput = document.querySelector("input[name='no_have_transport']")
  const yesInput = document.querySelector("input[name='endorsements_details']")

  transportRadios.forEach(btn => {
    btn.addEventListener('click', () => {
      if (btn.value === 'no') {
        noInput.parentNode.classList.add('active')
      } else {
        noInput.parentNode.classList.remove('active')
      }
    })
  })

  endorsementsRadios.forEach(btn => {
    btn.addEventListener('click', () => {
      if (btn.value === 'yes') {
        yesInput.parentNode.classList.add('active')
      } else {
        yesInput.parentNode.classList.remove('active')
      }
    })
  })
}

if (document.querySelectorAll('.js-default-choices')) {
  const defaultChoices = document.querySelectorAll('.js-default-choices')

  defaultChoices.forEach(choiceSelect => {
    var defaultSelect = new Choices(choiceSelect, {
      allowHTML: true,
      searchEnabled: false,
    });
  })
}
if (document.querySelector('#nationality')) {
  var singleNoSorting = new Choices('#nationality', {
    allowHTML: true,
    searchPlaceholderValue: 'Search...',
    shouldSort: false,
  });
}

if (document.querySelector(".choices")) {
  const choicesWrappers = document.querySelectorAll(".choices")
  choicesWrappers.forEach(choiceWrap => {

    choiceWrap.addEventListener('click', () => {
      if (!document.querySelector('.custom-simplebar-wrapper')) {
        const selectSimplebar = choiceWrap.querySelector(".choices__list--dropdown .choices__list");
        const div = document.createElement("div");
        div.classList.add('custom-simplebar-wrapper')
        const content = selectSimplebar.innerHTML;
        selectSimplebar.innerHTML = "";
        div.setAttribute("data-simplebar", "");
        div.innerHTML = content;
        selectSimplebar.appendChild(div);
      }
    })
  })
}

if (document.querySelector('.benefits__text')) {
  const spanElem = document.querySelectorAll('.benefits__text span')

  spanElem.forEach(span => {
    span.removeAttribute('style')
  })
}


